import '../_scss/index.scss'

const navbar_selector = document.querySelector('.navbar')
if (navbar_selector) {
    async function load() {
        const navbar = await import('./_navbar')
        navbar.toggleNavbar(navbar_selector)
    }
    load()
}

const map_selector = document.querySelector('.map')
if (map_selector) {
    async function load() {
        const map = await import('./map')
        map.loadMap(map_selector)
    }
    load()
}
